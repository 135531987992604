import { Facebook } from 'components/icons';
import { FormButton } from 'components/molecules/button/FormButton';
import React from 'react';
import styled from 'styled-components';

const FacebookIconWrapper = styled.div({
    position: 'absolute',
    left: '32px',
});

interface Props {
    text?: string;
    disabled: boolean;
    onClick?: (e: Event) => void;
}

export const FacebookButton = (props: Props) => {
    return (
        <FormButton
            height="50px"
            backgroundColor="#415893"
            disabled={props.disabled}
            onPress={props.onClick!}
        >
            <FacebookIconWrapper>
                <Facebook />
            </FacebookIconWrapper>
            {props.text ? props.text : 'Continue with Facebook'}
        </FormButton>
    );
};
