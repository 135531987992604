import React, { useState } from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';

interface ButtonProps {
    hover?: boolean;
    disabled?: boolean;
}

const InnerButton = styled(Text)<ButtonProps>((props) => ({
    '-webkit-tap-highlight-color': 'transparent',
    cursor: 'pointer',
    width: '6rem',
    height: '3rem',
    backgroundColor: props.disabled
        ? props.theme.colors.disabled
        : props.hover
        ? props.theme.colors.hover
        : props.theme.colors.primary,
    textAlign: 'center',
    transition: '0.15s all ease-in-out',
    fontFamily: `'${props.theme.fonts.default}'`,
}));

export const Button = (props: any) => {
    const [hover, setHover] = useState(false);
    return (
        <InnerButton
            {...props}
            hover={hover}
            accessibilityRole="button"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />
    );
};
