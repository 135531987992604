import { useStore } from 'lib/store';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';

import { getStateKey, useChannelLikesState, useChatInputState } from './chat/hooks/UseGlobalState';
import { MessageOfferType } from './chat/types';
import { MenuItemsWrapper } from './DefaultHeader';
import { Heart, RedHeart } from './icons';

interface MenuItemProps {
    color?: string;
    background?: string;
    highlightColor?: string;
    height?: number;
    width?: number;
    disableHover?: boolean;
}

const GroupMenuItem = styled.div<MenuItemProps>((props) => ({
    width: 300,
    '@media (max-width: 600px)': {
        width: '100%',
    },
    backgroundColor: props.theme.colors.background,
    color: props.theme.colors.text,
    textAlign: 'left',
    padding: 14,
    paddingLeft: 21,
    textDecoration: 'none',
    fontSize: 16,
    fontFamily: props.theme.fonts.default,
    lineHeight: '26px',
    display: 'flex',
    '&:hover': {
        backgroundColor: !props.disableHover && !isMobile ? '#f0f0f0' : 'none',
    },
    cursor: props.disableHover ? 'arrow' : 'pointer',
}));

const MenuItemLeftIcon = styled.div({
    width: 24,
    height: 24,
    marginRight: 12,
    cursor: 'pointer',
});

const MenuItemRightIcon = styled.div({
    width: 16,
    height: 16,
    cursor: 'pointer',
    marginLeft: 'auto',
});

interface IGroupMenuItem {
    text: string;
    favoriteText?: string;
    icon: string;
    offerType?: MessageOfferType;
}

const CHAT_MENU_ITEMS: Array<IGroupMenuItem> = [
    {
        icon: '🏁',
        text: 'Start',
    },
    {
        icon: '🏨',
        text: 'Hotels',
        favoriteText: 'Favorite Hotels',
        offerType: MessageOfferType.Accommodation,
    },
    {
        icon: '✈️',
        text: 'Flights',
        favoriteText: 'Favorite Flights',
        offerType: MessageOfferType.Flights,
    },
    {
        icon: '🎡',
        text: 'Tours and Activities',
        favoriteText: 'Favorite Tours and Activities',
        offerType: MessageOfferType.Activity,
    },
    {
        icon: '🚘',
        text: 'Car Rentals',
        favoriteText: 'Favorite Car Rentals',
        offerType: MessageOfferType.CarRental,
    },
];

const ChatMenuItems = ({ setIsOpen }: { setIsOpen: (open: boolean) => void }) => {
    const likesState = useChannelLikesState((state) => state);
    const setInput = useChatInputState((state) => state.setInput);
    const theme = useTheme();
    const isWidget = useStore((store) => store.isWidget);
    return (
        <MenuItemsWrapper>
            {CHAT_MENU_ITEMS.map((item) => {
                let hasLikedOffers = false;
                let text = item.text;
                if (
                    item.offerType !== undefined &&
                    likesState[getStateKey(item.offerType)] &&
                    Object.values(likesState[getStateKey(item.offerType)]).filter(
                        (likes) => likes.length !== 0
                    ).length !== 0
                ) {
                    hasLikedOffers = true;
                    text = item.favoriteText!;
                }
                return (
                    <GroupMenuItem
                        key={item.text}
                        onClick={() => {
                            setInput(text, true);
                            setIsOpen(false);
                        }}
                    >
                        <MenuItemLeftIcon>{item.icon}</MenuItemLeftIcon>
                        {item.text}
                        {!isWidget && item.favoriteText && (
                            <MenuItemRightIcon>
                                {hasLikedOffers ? (
                                    <RedHeart />
                                ) : (
                                    <Heart color={theme.colors.icon} />
                                )}
                            </MenuItemRightIcon>
                        )}
                    </GroupMenuItem>
                );
            })}
        </MenuItemsWrapper>
    );
};

export default ChatMenuItems;
