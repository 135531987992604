import { Button } from 'components/atoms/button';
import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';
import { StyledProps } from 'styled-components';
import styled from 'styled-components/native';

interface FormButtonProps {
    height?: string;
    fontSize?: number;
    fontWeight?: number;
    disabled?: boolean;
    color?: string;
    backgroundColor?: string;
    onPress: (e: Event) => void;
}

export const InnerFormButton = styled(Button)<FormButtonProps>(
    (props: StyledProps<FormButtonProps>) => {
        return {
            width: '100%',
            height: props.height || '50px',
            borderRadius: 4,
            fontSize: props.fontSize || '1rem',
            fontWeight: props.fontWeight,
            color: props.color ?? props.theme.colors.background,
            backgroundColor: props.backgroundColor,
            paddingTop: '1rem',
            opacity: props.disabled ? '0.5' : '1',
        };
    }
);

export const FormButton = (props: PropsWithChildren<FormButtonProps>) => {
    const { children, ...rest } = props;
    return (
        <View>
            <InnerFormButton {...rest}>{children}</InnerFormButton>
        </View>
    );
};
