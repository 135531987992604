import { useStore } from 'lib/store';
import { copyToClipboard } from 'lib/utils';
import { DateTime } from 'luxon';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';

import { Event, sendEvent } from '../lib/events';
import { useUserState } from './chat/hooks/UseGlobalState';
import { useMenu } from './chat/hooks/UseMenu';
import ChatMenuItems from './ChatMenuItems';
import {
    ExitIcon,
    Header,
    HeaderElementsWrapper,
    HeaderRightWrapper,
    MenuIcon,
    MenuIconWrapper,
} from './DefaultHeader';
import HeaderTitle from './HeaderTitle';
import { Heart, MagnifyingGlass, Settings, Share, Users } from './icons';
import { ArrowLeft } from './icons/ArrowLeft';

const { publicRuntimeConfig } = getConfig();

interface MenuIconProps {
    isActive?: boolean;
}

export const HeartIcon = styled(Heart)<MenuIconProps>((props) => ({
    height: 22,
    width: 24,
    background: props.isActive ? '#f0f0f0' : 'none',
    '&:hover': {
        background: !isMobile || props.isActive ? '#f0f0f0' : 'none',
    },
}));

export const MagnifyingGlassIcon = styled(MagnifyingGlass)<MenuIconProps>((props) => ({
    height: 24,
    width: 24,
    background: props.isActive ? '#f0f0f0' : 'none',
    '&:hover': {
        background: !isMobile || props.isActive ? '#f0f0f0' : 'none',
    },
}));

const SettingsMenuWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    height: '72px',
});

const SettingsMenuItemWrapper = styled.div({
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 33333,
    maxWidth: 640,
    flex: 1,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    justifyContent: 'center',
});

const SettingsMenuItem = styled.div((props) => ({
    paddingTop: '14px',
    paddingBottom: '14px',
    textAlign: 'center',
    color: props.theme.colors.darkgrey,
    ':hover': {
        color: 'black',
    },
    cursor: 'pointer',
    flex: 1,
}));

const SettingsMenuItemTitle = styled.div((props) => ({
    height: '14px',
    lineHeight: '14px',
    fontSize: '12px',
    fontFamily: props.theme.fonts.default,
}));

const UnreadMessagesBubble = styled.div((props) => ({
    height: 20,
    width: 20,
    background: '#EC4747',
    boxShadow: '0px 0px 5px rgba(255, 255, 255, 0.5)',
    borderRadius: 27,
    fontSize: 14,
    lineHeight: '22px',
    color: props.theme.colors.textLight,
    textAlign: 'center',
    position: 'relative',
    left: -10,
    fontFamily: props.theme.fonts.default,
}));

const HeaderLink = styled.a({
    textDecoration: 'none',
});

const SETTINGS_MENU_ITEMS = [
    {
        icon: Users,
        title: 'Members',
        link: 'settings#members',
    },
    {
        icon: Settings,
        title: 'Settings',
        link: 'settings',
    },
];

const ChatHeader = () => {
    const { isOpen, menuRef, setIsOpen } = useMenu();
    const [linkCopied, setLinkCopied] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const userGroups = useUserState((state) => state.groups);
    const router = useRouter();
    const group = userGroups.find((group) => group.id === router.query['id']);
    const unreadConversationsCount = userGroups.filter(
        (group) =>
            group.id !== router.query['id'] &&
            DateTime.fromISO(group.last_message_at) > DateTime.fromISO(group.last_message_read_at)
    ).length;
    const theme = useTheme();
    const isWidget = useStore((store) => store.isWidget);
    const isEmbedded = useStore((store) => store.isEmbedded);
    const hideCloseButton = useStore((store) => store.config.hideCloseButton);

    return (
        <Header>
            <HeaderElementsWrapper>
                {isWidget ? (
                    <MenuIconWrapper marginLeft={12}>
                        {!isEmbedded && hideCloseButton !== true ? (
                            <MenuIcon
                                aria-label="Close"
                                onClick={() => {
                                    sendEvent({ type: Event.WIDGET_CLOSED });
                                    window.parent.postMessage(
                                        { type: '__EDDY_TRAVELS_CHAT_CLOSE' },
                                        '*'
                                    );
                                }}
                            >
                                <ExitIcon color={theme.colors.icon} />
                            </MenuIcon>
                        ) : null}
                    </MenuIconWrapper>
                ) : (
                    <Link href="/home" passHref>
                        <HeaderLink>
                            <MenuIconWrapper marginLeft={6}>
                                <MenuIcon>
                                    <ArrowLeft color={theme.colors.icon} />
                                </MenuIcon>
                            </MenuIconWrapper>
                        </HeaderLink>
                    </Link>
                )}
                {!isWidget && unreadConversationsCount > 0 ? (
                    <Link href="/home" passHref>
                        <HeaderLink>
                            <UnreadMessagesBubble>{unreadConversationsCount}</UnreadMessagesBubble>
                        </HeaderLink>
                    </Link>
                ) : null}
                <HeaderTitle toggleSettings={() => setSettingsOpen(!settingsOpen)} />
                <HeaderRightWrapper>
                    <MenuIconWrapper ref={menuRef}>
                        {!isWidget ? (
                            <MenuIcon
                                onClick={() => setIsOpen((isOpen) => !isOpen)}
                                isActive={isOpen}
                                paddingTop={5}
                            >
                                <HeartIcon
                                    isActive={isOpen}
                                    width={24}
                                    height={22}
                                    color={theme.colors.icon}
                                />
                            </MenuIcon>
                        ) : null}
                        {isOpen && <ChatMenuItems setIsOpen={setIsOpen} />}
                    </MenuIconWrapper>
                </HeaderRightWrapper>
            </HeaderElementsWrapper>
            {settingsOpen ? (
                <SettingsMenuWrapper>
                    <SettingsMenuItemWrapper>
                        {group ? (
                            <SettingsMenuItem
                                onClick={async () => {
                                    const url = `${publicRuntimeConfig?.rootUrl}/join/${group?.share_token}`;
                                    if (navigator.share) {
                                        await navigator.share({
                                            title: group?.name,
                                            url: url,
                                        });
                                    } else {
                                        await copyToClipboard(url);
                                        setLinkCopied(true);

                                        setTimeout(() => {
                                            setLinkCopied(false);
                                        }, 5000);
                                    }
                                }}
                            >
                                <Share />
                                {!linkCopied ? (
                                    <SettingsMenuItemTitle>Share</SettingsMenuItemTitle>
                                ) : (
                                    <SettingsMenuItemTitle>
                                        Link copied <span css={{ color: 'green' }}>✓</span>
                                    </SettingsMenuItemTitle>
                                )}
                            </SettingsMenuItem>
                        ) : null}
                        {SETTINGS_MENU_ITEMS.map((item) => {
                            if (!group && item.title === 'Members') {
                                return null;
                            }

                            return (
                                <SettingsMenuItem
                                    key={item.title}
                                    onClick={() => {
                                        if (group) {
                                            router.push(`/groups/${group?.id}/${item.link}`);
                                        }
                                    }}
                                >
                                    {item.icon()}
                                    <SettingsMenuItemTitle>{item.title}</SettingsMenuItemTitle>
                                </SettingsMenuItem>
                            );
                        })}
                    </SettingsMenuItemWrapper>
                </SettingsMenuWrapper>
            ) : null}
        </Header>
    );
};

export default ChatHeader;
