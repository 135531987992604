import ChatHeader from 'components/ChatHeader';
import DefaultHeader from 'components/DefaultHeader';
import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const LayoutWrap = styled.div<{ overflowY: 'hidden' | 'scroll' | 'auto' }>((props) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: props.overflowY,
}));

const ContentWrap = styled.div({
    display: 'flex',
    height: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    '@media(max-width: 600px)': {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

const ContentOverflowFix = styled.div({
    flex: '1 1 0',
    minWidth: 0,
});

function ContentLayout(props: { children: ReactNode }) {
    const router = useRouter();
    const useLikesHeader = router.pathname === '/groups/[id]' || router.pathname === '/';
    const isWidget = useStore((store) => store.isWidget);
    return (
        <LayoutWrap overflowY={isWidget ? 'hidden' : 'scroll'}>
            {useLikesHeader ? <ChatHeader /> : <DefaultHeader />}
            <ContentWrap>
                <ContentOverflowFix>{props.children}</ContentOverflowFix>
            </ContentWrap>
        </LayoutWrap>
    );
}

export default ContentLayout;
